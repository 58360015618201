import request from '@/api/axios';

/**
 * 获取品牌粉丝数
 * @param {integer} brandId
 */
export function apiFetchBrandFansNum(id) {
  return request({
    url: '/cust/cust/brand/zone/follow/l_sum',
    method: 'get',
    params: { brandId: id },
  });
}

/**
 * 最新通知
 * @param {integer} brandId
 */
export function apiFetchNoticeForHome(id) {
  return request({
    url: '/cust/cust/brand/zone/latest/l_notice',
    method: 'get',
    params: { brandId: id },
  });
}

/**
 * 留言列表
 * brandId (string, optional): 品牌ID ,
 * endTime (string, optional): 结束日期（如：2008-08-08 23:59:59） ,
 * pageNum (integer, optional): 页码 ,
 * pageSize (integer, optional): 每页长度 ,
 * searchKey (string, optional): 查询条件 ,
 * startTime (string, optional): 开始日期（如：2008-08-08 00:00:00）
 */
export function apiFetchMessageList(params) {
  return request({
    url: '/cust/cust/brand/zone/message/l_list',
    method: 'post',
    data: params,
  });
}

/**
 * 查看留言
 * @param {integer} messageId
 */
export function apiFetchMessageDetail(id) {
  return request({
    url: '/cust/cust/brand/zone/message/reply/l_get',
    method: 'get',
    params: { messageId: id },
  });
}

/**
 * 回复留言
 * @param {integer} messageId
 * @param {string} messageReply
 */
export function apiPostReply(params) {
  return request({
    url: '/cust/cust/brand/zone/message/reply/l_save',
    method: 'post',
    data: params,
  });
}

/**
 * 销售周报
 */
export function apiFetchWeekSales() {
  return request({
    url: '/param/dataAnalysis/l_weekSales',
    method: 'get',
  });
}
