<template>
  <el-dialog
    custom-class="dialog-custom"
    :append-to-body="true"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <span
      slot="title"
      class="el-dialog__title"
    >{{ title }}</span>
    <slot />
  </el-dialog>
</template>
<script>
// import { IS_MOBILE, initPrototype } from '@/vuePrototypeInit'

// import { cpBaseProps } from "@/components/init"

export default {
  name: 'ZDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '标题',
      // default:null,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClose(done) {
      this.$emit('updata:visible', false);
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
/* .el-dialog {

  ::v-deep &.dialog-custom {
    width: 87.8% !important;
    max-width: 87.8% !important;
    border-radius: 10px !important;

    .el-dialog__header {
      height: auto !important;
      text-align: center;
      justify-content: center;
      background: none !important;
      align-items: flex-start !important;

      .el-dialog__title {
        min-width: 280px;
        width: 14.6rem;
        height: 3.5rem !important;
        line-height: 3.5rem !important;
        height: 56px;
        line-height: 56px;
        padding: 0 64px;
        color: #fff !important;
        font-size: 2rem !important;
        font-size: 24px !important;
        font-weight: bold;
        background: linear-gradient(90deg, #999999 0%, #666666 100%);
        border-radius: 0px 0px 2.5rem 2.5rem;
        border-radius: 0px 0px 50px 50px;
      }

      .el-dialog__headerbtn {
        width: auto !important;
        height: auto !important;
        padding: 4px;
        width: 3.2rem !important;
        height: 3.2rem !important;
        font-size: 36px !important;

        .el-dialog__close {
          font-size: 2rem !important;
        }
      }

    }

    .el-dialog__body {
      padding: 44px 26px !important;
    }
  }
} */

</style>
