<template>
  <div class="HomeUser">
    <!-- {{brandInfo.sbrandPic}} -->
    <div class="brandInfo">
      <div class="avatarWrap">
        <img
          class="avatar"
          :src="brandInfo.sbrandPic?brandInfo.sbrandPic:require('@/assets/images/user/user_img.jpg')"
        >
      </div>
      <p class="brandName">
        {{ brandInfo.sbrandName }}
      </p>
      <p class="fansNum">
        粉丝数：{{ fansNum }}
      </p>
    </div>
    <div class="location">
      <div class="item">
        <img
          class="icon"
          src="@/assets/images/user/12.png"
        >
        <span class="text">{{ address.city }}，{{ address.country }}</span>
      </div>
      <div class="item">
        <img
          class="icon"
          src="@/assets/images/user/11.png"
        >
        <span class="text">{{ getDateWeek() }}</span>
      </div>
    </div>
    <div class="entrance">
      <div
        class="entrance__item"
        @click="linkTo('/video/videoManage')"
      >
        <img
          class="entrance__item__icon"
          src="@/assets/images/home/icon-video.png"
        >
        <p class="entrance__item__title">
          视频管理
        </p>
      </div>
      <div
        class="entrance__item"
        @click="linkTo('/message/articleManage')"
      >
        <img
          class="entrance__item__icon"
          src="@/assets/images/home/icon-doc.png"
        >
        <p class="entrance__item__title">
          文章管理
        </p>
      </div>
      <div
        class="entrance__item"
        @click="linkTo('/report/center')"
      >
        <img
          class="entrance__item__icon"
          src="@/assets/images/home/icon-report.png"
        >
        <p class="entrance__item__title">
          数据中心
        </p>
      </div>
      <div
        class="entrance__item"
        @click="linkTo('/messreply/messageBoard')"
      >
        <img
          class="entrance__item__icon"
          src="@/assets/images/home/icon-message.png"
        >
        <span class="entrance__item__num">{{ newMessage }}</span>
        <p class="entrance__item__title">
          留言板
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  getWelCount,
} from '@/api/user';
import {
  apiFetchBrandFansNum,
} from '@/api/home';

import {
  getIpApiJson,
} from '@/api/customer';

export default {
  name: 'HomeUser',
  props: {
    newMessage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      address: {
        city: '',
        country: '中国',
      },
      // 粉丝数
      fansNum: 0,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'brandInfo']),
    // ws type==MALL 的消息总数
    noticeTotalNum() {
      return this.$store.state.common.totalNumForMallNotice;
    },
  },
  created() {
    this.fetchFanceNum();
    this.getCity();
  },
  methods: {
    getCity() {
      const reg = /.+?(市|自治区|自治州)/g;

      const _this = this;
      const proviceId = `${returnCitySN.cid.substring(0, 2)}0000`;
      getIpApiJson(proviceId).then((response) => {
        // const {code,data} = response.data
        if (response) {
          // 北京市，上海市，天津市，重庆市
          if (response.scityName == '北京市' || response.scityName == '上海市' || response.scityName == '天津市' || response.scityName == '重庆市') {
            _this.address.city = response.scityName;
          } else {
            getIpApiJson(returnCitySN.cid).then((response) => {
              // const {code,response} = response.response
              if (response) {
                _this.address.city = response.scityName;
              }
            });
          }
        }
      });
    },
    getDateWeek() {
      const todayDate = new Date();
      const date = todayDate.getDate();
      const month = todayDate.getMonth() + 1;
      const year = todayDate.getYear();
      let dateweek = '';
      if (navigator.appName === 'Netscape') {
        dateweek = `${dateweek + (1900 + year)}年${month}月${date}日 `;
      }
      if (navigator.appVersion.indexOf('MSIE') != -1) {
        dateweek = `${dateweek + year}年${month}月${date}日 `;
      }
      switch (todayDate.getDay()) {
        case 0:
          dateweek += '星期日';
          break;
        case 1:
          dateweek += '星期一';
          break;
        case 2:
          dateweek += '星期二';
          break;
        case 3:
          dateweek += '星期三';
          break;
        case 4:
          dateweek += '星期四';
          break;
        case 5:
          dateweek += '星期五';
          break;
        case 6:
          dateweek += '星期六';
          break;
      }
      return dateweek;
    },
    // 获取粉丝数量
    fetchFanceNum() {
      apiFetchBrandFansNum().then((response) => {
        if (response) {
          this.fansNum = response;
        }
      });
    },
    // 路由跳转
    linkTo(path) {
      this.$router.push(path);
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
.HomeUser {
  text-align: center;
}

.brandInfo {
  padding-bottom: 26px;
  border-bottom: 1px solid #eaeaea;
}

.location {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 2%;
  justify-content: space-around;

  .item {
    flex: none;
    margin: 8px 0;
    color: #666;
    font-size: 14px;

    .icon {
      margin-right: 6px;
      vertical-align: -2px;
    }

    .text {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.entrance {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15% 0 0;
  padding: 0 10.6%;

  .entrance__item {
    position: relative;
    width: 50%;
    /*margin: 5% 13.8%;*/
    margin: 5% 0%;
    cursor: pointer;

    .entrance__item__icon {
      border-radius: 30px;
    }

    .entrance__item__num {
      position: absolute;
      left: 50%;
      margin-left: 12px;
      top: -3px;
      min-width: 22px;
      height: 19px;
      padding: 0 4px;
      box-sizing: border-box;
      line-height: 19px;
      font-size: 12px;
      font-weight: bold;
      color: #E48B00;
      background: linear-gradient(-40deg, #ffd000 0%, #ffdc41 100%);
      border-radius: 10px;
    }

    .entrance__item__title {
      margin: 8px 0 0;
      color: #666;
      font-size: 14px;
    }

    &.active,
    &:hover {
      .entrance__item__icon {
        box-shadow: 0px 2px 10px 0px rgba(40, 204, 204, 0.56);
      }
    }
  }
}

.avatarWrap {
  margin: -42px auto 0;
  padding: 8px;
  display: flex;
  /*width: 147px;*/
  /*height: 147px;*/
  width: 38%;
  aspect-ratio: 1/1;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;

  .avatar {
    max-width: 100%;
  }
}

.brandName {
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #666666;
  line-height: 1em;
}

.fansNum {
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 1em;
  color: #999999;
}

</style>
