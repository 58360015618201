<template>
  <HomePanel class="panel--chart">
    <template #header>
      <img
        src="@/assets/images/home/title-sales.png"
        alt="最新留言"
      >
    </template>
    <div
      id="main"
      style="width:100%;height: 100%;"
    />
  </HomePanel>
</template>
<script>
import * as echarts from 'echarts';
import HomePanel from './HomePanel';
import { apiFetchWeekSales } from '@/api/home';

export default {
  name: 'HomeChart',
  components: { HomePanel },
  data() {
    return {
      myChart: null,
      yData: [],
      xData: [],
    };
  },
  mounted() {
    this.fetchWeekSales();
    // this.createChart()
    window.addEventListener('resize', () => {
      if (this.myChart) {
        this.myChart.resize();
      }
    });
  },
  methods: {
    fetchWeekSales() {
      apiFetchWeekSales().then((response) => {
        if (response) {
          const { data } = response;
          this.xData = data.map((item) => item.dsalesDate);
          this.yData = data.map((item) => (item.ntotalAmount / 10000).toFixed(2));
          this.createChart();
        }
      });
    },
    createChart() {
      const chartDom = document.getElementById('main');
      this.myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'axis',
          position(pt) {
            return [pt[0] - 100, '10px'];
          },
          formatter(params) {
            return `<p>${params[0].name}</p><p>销售收入：${params[0].data}</p>`;
          },
          // formatter: '{b0}: {c0}',
          textStyle: {
            color: '#fff',
          },
          backgroundColor: '#212945',
          borderWidth: 0,
        },
        grid: {
          left: 50,
          right: 40,
          top: 30,
          bottom: 30,
          top: 50,
        },
        xAxis: {
          type: 'category',
          // type: 'value',
          boundaryGap: false,
          data: this.xData,
          axisLine: {
            show: false,
          },
          // 坐标轴刻度相关设置
          axisTick: {
            // show:false,
            inside: true,
            lineStyle: {
              width: 2,
              color: '#d9dee8',
            },
          },
          // 坐标轴刻度标签的相关设置
          axisLabel: {
            margin: 20,
            color: '#9aa1b5',
          },
          // 坐标轴在 grid 区域中的分隔线
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#d6dae4',
            },
          },
          // 坐标轴指示器
          axisPointer: {
            // show:false
            snap: true,
            lineStyle: {
              // color:'#5e69f9',
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#95b8fc', // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#ada1f9', // 100% 处的颜色
                }],
                global: false, // 缺省为 false
              },
              width: 2,
              type: 'solid',
            },
          },
        },
        yAxis: {
          name: '万元',
          nameLocation: 'end',
          nameTextStyle: {
            // color:'red'
            // padding:[0,40,0,0]
          },
          type: 'value',
          // 坐标轴刻度标签的相关设置
          axisLabel: {
            color: '#9aa1b5',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#d6dae4',
            },
          },
        },
        markPoint: {
          symbol: 'rect',
        },
        series: [{
          data: this.yData,
          type: 'line',
          smooth: true,
          showSymbol: false,
          // 线条样式
          lineStyle: {
            width: 3,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#4b8aff', // 0% 处的颜色
              }, {
                offset: 1,
                color: '#9b86ff', // 100% 处的颜色
              }],
              global: false, // 缺省为 false
            },
            shadowColor: 'rgba(155,134,255, 0.4)',
            shadowBlur: 10,
          },
          // 区域填充样式
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(114, 123, 250,.1)',
            },
            {
              offset: 1,
              color: 'rgba(114, 123, 250,0.01)',
            },
            ]),
          },
          emphasis: {

          },
        }],
      };

      option && this.myChart.setOption(option);
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
.panel--chart {
  flex: 1;

  ::v-deep .panel__bd {
    margin: 0;
    padding: 0 20px 26px;
    overflow: visible;
  }
}

.messageItem {
  display: flex;
  align-items: center;
  padding: 16px 0 18px;
  border-bottom: 1px solid #eaeaea;

  .messageItem__colLeft {
    position: relative;
    flex: 1;
    padding-left: 36px;

    &::before {
      position: absolute;
      left: 19px;
      top: 7px;
      content: '';
      width: 10px;
      height: 10px;
      border: 2px solid #EAEAEA;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .messageItem__colLeft__title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.4em;
      color: #666;
      font-size: 16px;
      font-weight: bold;
    }

    .messageItem__colLeft__date {
      margin: 6px 0 0;
      color: rgba(51, 51, 51, .7);
      font-size: 12px;
    }
  }

  .messageItem__colRight {
    flex: none;
    padding: 0 28px;
  }
}

</style>
