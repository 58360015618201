<template>
  <HomePanel class="panel--notice">
    <template #header>
      <img
        src="@/assets/images/home/title-notice.png"
        alt="最新留言"
      >
    </template>
    <div
      v-for="notice in noticeInfo"
      class="messageItem"
    >
      <div class="messageItem__colLeft">
        <p class="messageItem__colLeft__title">
          您有 {{ notice.sum }} <template v-if="notice.type==='guestbook'">
            条待回复留言
          </template><template v-else-if="notice.type==='fans'">
            个新粉丝
          </template>
        </p>
        <p class="messageItem__colLeft__date">
          {{ notice.latestDate }}
        </p>
      </div>
      <div
        v-if="notice.type==='guestbook'"
        class="messageItem__colRight"
      >
        <z-button
          type="primary"
          size="medium"
          plain
          @click="$router.push('/messreply/messageBoard')"
        >
          查看详情
        </z-button>
      </div>
    </div>
    <div
      v-if="!noticeInfo.length"
      class="empty-container"
    >
      暂无通知
    </div>
  </HomePanel>
</template>
<script>
import { mapGetters } from 'vuex';
import HomePanel from './HomePanel';

export default {
  name: 'HomeNotice',
  components: { HomePanel },
  props: {
    noticeInfo: {
      type: Array,
      default: [],
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
.panel--notice {
  flex: 1;
}

.messageItem {
  position: relative;
  display: flex;
  align-items: end;
  padding: 16px 0 18px;
  border-bottom: 1px solid #eaeaea;
  overflow: hidden;

  &::before {
    position: absolute;
    /*left: 19px;*/
    left: 3.2%;
    top: 21px;
    content: '';
    width: 10px;
    height: 10px;
    border: 2px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .messageItem__colLeft {
    position: relative;
    flex: 1;
    /*padding-left: 36px;*/
    padding-left: 6.1%;

    .messageItem__colLeft__title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.4em;
      color: #666;
      font-size: 16px;
      font-weight: bold;
    }

    .messageItem__colLeft__date {
      margin: 6px 0 0;
      color: rgba(51, 51, 51, .7);
      font-size: 12px;
    }
  }

  .messageItem__colRight {
    flex: none;
    width: 25%;
    min-width: 120px;
    text-align: center;
  }
}
.empty-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 16px;
}

</style>
