import { render, staticRenderFns } from "./HomeNotice.vue?vue&type=template&id=16935330&scoped=true&"
import script from "./HomeNotice.vue?vue&type=script&lang=js&"
export * from "./HomeNotice.vue?vue&type=script&lang=js&"
import style0 from "./HomeNotice.vue?vue&type=style&index=0&id=16935330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16935330",
  null
  
)

export default component.exports