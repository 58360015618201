<template>
  <HomePanel class="panel--message">
    <template #header>
      <img
        src="@/assets/images/home/title-message.png"
        alt="最新留言"
      >
    </template>
    <div
      v-for="item in messageList"
      :key="item.id"
      class="messageItem"
    >
      <div class="messageItem__colLeft">
        <p
          class="messageItem__colLeft__title"
          @click="showDialogMessage(item)"
        >
          {{ item.realName }}：{{ item.description }}
        </p>
        <p
          class="
          messageItem__colLeft__date"
        >
          {{ item.updateTime }}
        </p>
      </div>
      <div class="messageItem__colRight">
        <z-button
          type="primary"
          size="medium"
          plain
          @click="showDialogMessage(item)"
        >
          <template v-if="item.isShowReply===0">
            查看并回复
          </template>
          <template v-else-if="item.isShowReply===1">
            查看回复
          </template>
        </z-button>
      </div>
    </div>
    <div
      v-if="!messageList.length"
      class="empty-container"
    >
      暂无数据
    </div>
    <DialogMessage
      v-if="visibleDialog"
      :visible.sync="visibleDialog"
      :dialog-type="dialogType"
      :message-id="messageId"
      @succ="fetchMessageList"
    />
  </HomePanel>
</template>
<script>
import { mapGetters } from 'vuex';
import HomePanel from './HomePanel';
import DialogMessage from '@/components/message/DialogMessage';
import { apiFetchMessageList } from '@/api/home';

export default {
  name: 'HomeMessage',
  components: { DialogMessage, HomePanel },
  data() {
    return {
      visibleDialog: false,
      // 弹窗类型 0：查看并回复  1：查看
      dialogType: null,
      messageId: null,
      messageList: [],

    };
  },
  computed: {
    ...mapGetters(['brandInfo']),
  },
  created() {
    this.fetchMessageList();
  },
  methods: {
    showDialogMessage(item) {
      this.dialogType = item.isShowReply;
      this.messageId = item.id;
      this.visibleDialog = true;
    },
    fetchMessageList() {
      const params = {
        brandId: this.brandInfo.nbrandId,
        pageNum: 1,
        pageSize: 3,

      };
      apiFetchMessageList(params).then((response) => {
        if (response) {
          ({ datas: this.messageList } = response);
        }
      });
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
.panel--message {
  flex: 1;
  height: 100%;
  margin-right: 1.46vw;

}

.messageItem {
  position: relative;
  display: flex;
  align-items: end;
  padding: 16px 0 18px;
  border-bottom: 1px solid #eaeaea;
  overflow: hidden;

  &::before {
    position: absolute;
    /*left: 19px;*/
    left: 3.2%;
    top: 21px;
    content: '';
    width: 10px;
    height: 10px;
    border: 2px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .messageItem__colLeft {
    position: relative;
    flex: 1;
    /*padding-left: 36px;*/
    padding-left: 6.1%;

    .messageItem__colLeft__title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.4em;
      color: #666;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }

    .messageItem__colLeft__date {
      margin: 6px 0 0;
      color: rgba(51, 51, 51, .7);
      font-size: 12px;
    }

  }

  .messageItem__colRight {
    flex: none;
    width: 25%;
    min-width: 120px;
    text-align: center;
  }

  &:hover {
    .messageItem__colLeft__title {
      color: #19b0b0;
    }

  }
}
.empty-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 16px;
}
</style>
