<template>
  <el-dialog
    custom-class="dialog-message"
    title="留言回复"
    :append-to-body="true"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :before-close="handleClose"
    width="520px"
  >
    <div v-loading="loading">
      <!-- 管理员回复 -->
      <div
        v-if="dialogType===1"
        class="message message-management"
      >
        <h3 class="message__title">
          {{ brandInfo.sbrandName }}星选官<i class="icon-v" />
        </h3>
        <p class="message__info">
          <span class="message__info__title">回复时间：</span>{{ messageDetail.replyTime }}
        </p>
        <div class="message__content">
          {{ messageDetail.respond }}
        </div>
      </div>
      <!-- 管理员回复 end -->
      <!-- 用户留言 -->
      <div
        class="message"
        :class="{'message--hasReply':dialogType===1}"
      >
        <h3 class="message__title">
          留言内容
        </h3>
        <p class="message__info">
          <span class="message__info__title">提交人：</span>{{ messageDetail.realName }}
        </p>
        <p class="message__info">
          <span class="message__info__title">提交时间：</span>{{ messageDetail.messageTime }}
        </p>
        <div class="message__content">
          {{ messageDetail.description }}
        </div>
      </div>
      <!-- 用户留言 end -->
      <div
        v-if="dialogType===0"
        class="messageReply"
      >
        <el-form
          ref="replyForm"
          :rules="rules"
          :model="replyForm"
          label-width="0"
          label-position="top"
        >
          <el-form-item prop="messageReply">
            <el-input
              v-model.trim="replyForm.messageReply"
              class="messageReply__textarea"
              type="textarea"
              placeholder="您最多输入200字"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
          <z-button
            type="primary"
            @click="reply"
          >
            回复
          </z-button>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import ZDialog from '../common/ZDialog.vue';
import { apiFetchMessageDetail, apiPostReply } from '@/api/home';

export default {
  name: 'DialogMessage',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: Number,
      default: null,
    },
    messageId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['brandInfo']),
  },
  data() {
    return {
      messageDetail: {},
      replyForm: {
        messageId: this.messageId,
        messageReply: '',
      },
      rules: {
        messageReply: { required: true, message: '请输入回复内容', trigger: 'blur' },
      },
      loading: false,

    };
  },
  created() {
    this.fetchMessageDetail();
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    fetchMessageDetail() {
      this.loading = true;
      apiFetchMessageDetail(this.messageId).then((response) => {
        if (response) {
          [this.messageDetail] = response;
        }
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
      });
    },
    postReply() {
      apiPostReply(this.replyForm).then((response) => {
        if (response) {
          this.$message.success(response);
          this.$emit('succ');
          this.handleClose();
        }
      }).catch((e) => {
        if (e.message) {
          this.$message.error(e.message);
        }
      });
    },
    reply() {
      this.$refs.replyForm.validate((valid) => {
        if (valid) {
          this.postReply();
        }
      });
    },
  },
};

</script>
<style
  lang="scss"
  scoped
>
::v-deep .dialog-message {
  .el-dialog__body {
    padding-bottom: 50px;
  }
}

.message {
  .message__title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  .message__info {
    line-height: 1.4em;
    font-size: 14px;
    color: #666;

    .message__info__title {
      margin-right: 4px;
      color: #999;
    }

  }

  .message__content {
    margin-top: 16px;
    line-height: 1.5em;
    color: #333;
  }

  &.message--hasReply {
    margin: 16px 0 0;
    padding: 20px 20px 16px;
    border-radius: 4px;

    background: rgba(234, 234, 234, .5);

    .message__title {
      color: #999;
    }
  }

  &.message-management {
    .message__title {
      color: #0099a1;
      font-size: 16px;

      .icon-v {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 14px;
        margin: 0 0 0 4px;
        background: url('~@/assets/images/icon-v.png');
      }
    }
  }
}

.messageReply {
  margin: 36px 0 0;

  .messageReply__textarea {
    height: 120px !important;
    padding: 2px;
    background: #FFFFFF;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    background: none !important;
    box-sizing:border-box!important;

    ::v-deep .el-textarea__inner {
      padding: 6px 8px;
      background: none !important;
      resize: none;
    }

    ::v-deep .el-input__count {
      display: block;
      margin-bottom: 2px;
      line-height: 1em;
    }
  }

  .z-btn {
    display: block;
    margin: 42px auto 0;
  }
}

</style>
<style lang="scss">
/*.messageReply {
  .el-textarea .el-input__count {
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
}
*/

</style>
